body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 1rem !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h4,
p {
  overflow-wrap: break-word;
}

.fontw-500 {
  font-weight: 500;
}

.color-000 {
  color: #000 !important;
}

.color-555555 {
  color: #555555 !important;
}

.color-383838 {
  color: #383838 !important;
}

.color-575757 {
  color: #575757 !important;
}

.color-292C2F {
  color: #292c2f !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #cea05f !important;
}

.color-454545 {
  color: #454545 !important;
}

.color-333333 {
  color: #333333 !important;
}

.color-000000DE {
  color: #000000de !important;
}

.color-848484 {
  color: #848484 !important;
}

.color-00000061 {
  color: #00000061 !important;
}

.color-232323 {
  color: #232323 !important;
}

.color-272833 {
  color: #272833 !important;
}

.color-242A2E {
  color: #242a2e !important;
}

.color-CEA05F {
  color: #cea05f !important;
}

.myprofile-font {
  color: #000;
  font-weight: 600;
  font-size: 15px;
}

.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}

.color-222426 {
  color: #222426 !important;
}

.color-555555 {
  color: #555555 !important;
}

.color-949494 {
  color: #949494 !important;
}

.color-848484 {
  color: #848484 !important;
}

.img-dim2 {
  width: 90px !important;
  height: 26px !important;
}

.color-292C2F {
  color: #292c2f !important;
}

.color-00000061 {
  color: #00000061 !important;
}

.color-AAAAAA {
  color: #aaaaaa !important;
}

.slick-next,
.slick-prev {
  top: 50%;
  width: 20px;
  height: 20px;
}

.color-BCBCBC {
  color: #bcbcbc !important;
}

.color-fff {
  color: #ffffff !important;
}

.space-6 {
  padding: 60px !important;
}

.space-7 {
  padding: 70px !important;
}

.space-8 {
  padding: 80px !important;
}

.space-marign-top-10 {
  margin-top: 20px !important;
}

.space-marign-bottom-10 {
  margin-bottom: 10px !important;
}

.space-marign-10 {
  margin: 10px 0px !important;
}

.space-marign-20 {
  margin: 20px 0px !important;
}

.bg-color-F5F5F5 {
  background: #f5f5f5 !important;
}

.color-767676 {
  color: #767676 !important;
}

.space-top-7 {
  padding-top: 7px !important;
}

.space-top-8 {
  padding-top: 8px !important;
}

.space-top-9 {
  padding-top: 9px !important;
}

.space-top-6 {
  padding-top: 6px !important;
}

.space-top-5 {
  padding-top: 5px !important;
}

.slick-dots li button {
  color: #bc8960 !important;
}

.slick-dots li.slick-active button:before {
  color: #bc8960 !important;
}

.borderbox {
  border: solid greenyellow;
}

.btndisble {
  background: transparent linear-gradient(90deg, #fafafa 0%, #040404 100%) 0% 0%
    no-repeat padding-box !important;
}

.whitebackground .MuiStepper-alternativeLabel {
  background-color: #fafafa !important;
}

.searchoption {
  width: 100% !important;
  background: rgb(255, 255, 255);
  margin: 0px auto;
  display: inline-block;
  border-bottom: solid 1px #000 !important;
  border: solid 1px #fff;
  padding: 12px;
  margin-top: 17px;
  font-size: 18px;
}

.searchoption::placeholder {
  opacity: 1;
  /* Firefox */
  font-size: 18px;
}

.displaybox {
  list-style-type: none;
  background-color: #fff;
  height: 180px;
  overflow-y: scroll;
  width: 115%;
  position: relative;
  z-index: 99999;
}
.displaybox1 {
  list-style-type: none;
  background-color: #fff;
  /* height: 180px; */
  overflow: none;
  width: 115%;
  position: relative;
  z-index: 99999;
}
.displaydropdown {
  list-style-type: none;
  background-color: #fff;
  height: 180px;
  overflow-y: scroll;
  width: 100%;
  position: relative;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  z-index: 99999;
}

.searchlist {
  list-style: none;
  /* border-bottom: solid 1px; */
  max-height: 220px;
  overflow: scroll;
  width: 325px;
}

.inventrysearch {
  background-color: #fff;
  position: absolute;
  width: 408px !important;
  margin: 0;
  overflow-x: hidden;
  z-index: 1000;
}

.searchkeyword {
  width: 100%;
  background: rgb(255, 255, 255);
  margin: 0px auto;
  display: inline-block;
  /* bo: ; */
  border: solid 1px #cbcbc4 !important;
  border: solid 1px #fff;
  /* padding-top: 29px; */
  margin-top: 1px;
  /* font-size: 18px; */
  border-radius: 5px;
  padding: 16px 10px;
}

.boxwidth {
  width: 408px !important;
}
.swal2-show {
  animation: swal2-show 0.3s;
  background-color: #f5f5f5f5;
  border: solid 1px;
}
.swal2-popup {
  display: none;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 50em;
  height: 27em;
  max-width: 100%;
  padding: 1.25em;
  border-radius: 0.3125em;
  background: #fff;
  font-family: inherit;
  font-size: 0.8rem;
  box-sizing: border-box;
}

.fl-right {
  float: right;
}
.fl-left {
  float: left;
}
.inventry-img {
  width: 180px !important;
  height: 180px !important;
  object-fit: contain;
}

.fa-minus-circle:before {
  content: "\f056";
  color: red;
  margin: 2px;
  font-size: 15px;
  margin: 0px 0px 0px 10px;
}
.img-width-22 {
  width: 400px !important;
  height: 350px !important;
  object-fit: contain !important;
}
/* .form-width-70 .MuiSlider-markLabelActive{
    left:4% !important;
}
.MuiSlider-markLabel {
    left:96% !important
} */
/* .makeStyles-root-85 {
    display: flex;
    flex-grow: 1;
    background-color: #fff;
} */
.extra-space-10 {
  margin-top: 20px;
}
.img-responsive2 {
  display: block;
  width: 100% !important;
  height: 300px !important;
  object-fit: contain;
  padding-bottom: 30px;
}
.center-text {
  text-align: center;
  padding: 20px;
}
.boxshodow {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.boxtitle {
  text-align: center;
  padding: 10px;
  font-weight: bold !important ;
  color: #cea05f !important;
}
.maxminunheight .someextrahight {
  height: auto !important;
  display: flex;
  flex-grow: 1;
  background-color: #fff !important;
}
.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loading div {
  position: absolute;
  background: #fff;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -0.7s;
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.fa-instagram {
  position: absolute;
  color: #fff;
  top: 6%;
  font-size: 1.5em;
  /* padding: 0px -7px; */
}
.fa-linkedin {
  position: absolute;
  color: #fff;
  top: 6%;
  font-size: 1.5em;
  /* padding: 0px -7px; */
}
.fa-facebook {
  position: absolute;
  color: #fff;
  top: 6%;
  font-size: 1.5em;
  /* padding: 0px -7px; */
}
.fa-twitter {
  position: absolute;
  color: #fff;
  top: 6%;
  font-size: 1.5em;
  /* padding: 0px -7px; */
}
/* .fa-instagram:hover{
    font-size: 42px;
    color: #981919;
    transition: all .1s linear;
    cursor: pointer;
  } */
.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 9999;
}
.newheight {
  background-color: #fff !important;
}
.imagebox {
  border: solid 1px #d9ecf1;
  background-color: #d9ecf1;
}
/* .extraleft .MuiSlider-markLabel {
      left: 98% !important;
  } */
.randamfont {
  font-size: 13px;
}
.d-block {
  display: block;
}
.sliderclass .MuiSlider-markLabelActive {
  left: 4%;
}
.sliderclass .MuiSlider-markLabel {
  left: 96%;
}
.img-box {
  justify-content: center;
  display: flex !important;
}

.buttonslider3 {
  margin-top: 18px !important;
  height: 46px !important;
  width: 100% !important;
}

@media only screen and (max-width: 1024px) {
  .searchoption {
    width: 100% !important;
    background: rgb(255, 255, 255);
    margin: 0px auto;
    display: inline-block;
    border-bottom: solid 1px #000 !important;
    border: solid 1px #fff;
    padding: 10px;
    margin-top: 17px;
    font-size: 18px;
  }
  .buttonslider3 {
    margin-top: 17px !important;
    height: 41px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .searchoption {
    width: 100% !important;
    background: rgb(255, 255, 255);
    margin: 0px auto;
    display: inline-block;
    border-bottom: solid 1px #000 !important;
    border: solid 1px #fff;
    padding: 5px;
    margin-top: 17px;
    font-size: 18px;
  }
  .buttonslider3 {
    margin-top: 17px !important;
    height: 33px !important;
    width: 100% !important;
  }
  #customized-menu {
    position: absolute;
    inset: 330px 0px 0px 10px;
    top: 26% !important;
    left: -6px !important;
  }
}

@media only screen and (max-width: 590px) {
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 16px;
    text-align: center;
    width: 55px;
    font-size: 14px;
    overflow-wrap: break-word;
  }
  .MuiStepper-root {
    display: flex;
    padding: 15px !important;
  }
  #customized-menu {
    position: absolute;
    inset: 330px 0px 0px 10px;
    top: 54% !important;
    left: -5px !important;
  }
}

/* .inventrybox {
    overflow: scroll;
    height: 280px;
  } */
.d-word {
  width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 15px;
}
.d-pt {
  margin-top: 15px;
}
.form-width-60 {
  width: 60% !important;
}
.bg-btn {
  padding: 7px 0px 7px 0px !important;
}
.fa-pencil:before {
  content: "\f040";
  color: #fff;
}
.blue {
  opacity: 0.3;
}

.MuiFormControl-root {
  border: 0;
  margin: 0;
  /* display: inline-flex; */
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}
.MuiGrid-spacing-xs-4 {
  width: calc(100% + 32px);
  margin: -40px;
}
.fa-times-circle:hover {
  cursor: pointer;
}

.MuiSlider-valueLabelLabel {
  margin-left: 2px !important;
}
.MuiSlider-valueLabel span:nth-child(1) {
  margin-top: 0 !important;
  margin-left: -1px !important;
  height: 49px !important;
  width: 49px !important;
  text-align: center;
}
.backdrop {
  /* z-Index: theme.zIndex.drawer + 1, */
  color: "#fff";
}
.spp {
  opacity: 1;
  position: relative;
  top: 2em;
  right: 5%;
  padding: 100px;
  bottom: 60em;
}
.sperator {
  font-size: 16px;
  margin: 0 1px;
}

.link:hover {
  color: black !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.d-spinner {
  justify-content: center;
  display: flex;
  width: 100%;
  height: 80vh;
  /* background:linear-gradient(rgb(49 49 49 / 80%), rgb(56 57 58 / 90%)); */
}
.d-spinner1 {
  justify-content: center;
  display: flex;
}

@media print {
  .noprint {
    visibility: hidden;
  }
}

.inventory-tile {
  display: block;
  text-align: left;
  padding-left: 15px;
  height: 3px;
}
.MuiTablePagination-select .MuiTablePagination-menuItem,
.css-m3l1ig-MuiNativeSelect-select-MuiInputBase-input:not([multiple]) option {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  color: inherit;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  min-height: 48px;
  padding: 6px 16px;
  box-sizing: border-box;
  white-space: nowrap;
}

.dealerbox {
  /* cursor: pointer; */
  background: white;
  /* padding-top: 20px;
  padding-bottom: 20px; */
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: box-shadow 0.3s ease;
  flex: 1 1 auto;
  border: 1px solid #ccc;
}

.dealerbox:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.dealer-container {
  display: flex;
  flex-wrap: wrap;
}

.inventory-card-container {
  display: flex;
  flex-wrap: wrap;
}

.inventory-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.contact-add-field {
  padding-left: 14px !important;
  color: rgb(66, 66, 66) !important;
}

.button-hover:hover {
  color: #fff !important;
}

@media (max-width: 900px) {
  .dealer-img {
    width: 180px !important;
    height: 180px !important;
    object-fit: contain;
  }
}

.loginLoader {
  width: 7px;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
  animation: loading-dot 0.7s infinite alternate linear;
  transform: scale(0.5);
}

@keyframes loading-dot {
  50% {
    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
  }
  100% {
    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
  }
}
